<template>
  <div class="menu_container">

  <el-menu style="margin-top: 50px" :collapse="isCollapse" :unique-opened="true">



    <el-submenu index="1" class="title">
      <template slot="title"><i class="el-icon-menu"></i>首页</template>
      <el-menu-item-group>
        <el-menu-item index="1-1" @click="$router.push({ name: 'dashboard', params: { title: '首页' } })">欢迎页</el-menu-item>
        <el-menu-item index="1-2" @click="$router.push({ name: 'ana', params: { title: '首页' } })">AIChat数据概要</el-menu-item>
      </el-menu-item-group>
    </el-submenu>


      <el-submenu index="3" class="title">
        <template slot="title"><i class="el-icon-menu"></i>订单管理</template>
        <el-menu-item-group>
          <el-menu-item index="3-1" @click="$router.push({ name: 'order_list', params: { title: '订单管理' } })"><i class="el-icon-goods"></i>订单管理</el-menu-item>
        </el-menu-item-group>
      </el-submenu>




      <el-submenu index="4" class="title">
        <template slot="title"><i class="el-icon-share"></i>配置管理</template>
        <el-menu-item-group>
          <el-menu-item index="4-3" @click="$router.push({ name: 'adminsetting2', params: { title: '配置管理' } })">共用配置</el-menu-item>
          <el-menu-item index="4-1" @click="$router.push({ name: 'income_relationship', params: { title: '配置管理' } })">推广关系</el-menu-item>
          <el-menu-item index="4-2" @click="$router.push({ name: 'cicd_file', params: { title: '配置管理' } })">CICD文件</el-menu-item>
          <el-menu-item index="4-4" @click="$router.push({ name: 'chat_rule', params: { title: '配置管理' } })">客服知识库</el-menu-item>

        </el-menu-item-group>
      </el-submenu>
      
    <el-submenu index="2" style="display: ">
      <template slot="title"><i class="el-icon-setting"></i>AIChat项目</template>
      <el-menu-item-group>
        <el-submenu index="2-1">
          <template slot="title">用户管理</template>
          <el-menu-item-group>
            <el-menu-item index="2-1-1" @click="$router.push({ name: 'user_list', params: { title: 'AIChat项目' } })">用户列表</el-menu-item>
            <el-menu-item index="2-1-2" @click="$router.push({ name: 'chat', params: { title: 'AIChat项目' } })">对话历史</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="2-2">
          <template slot="title">配置管理</template>
          <el-menu-item-group>
            <el-menu-item index="2-2-1" @click="$router.push({ name: 'openai_account', params: { title: 'AIChat项目' } })">openai账号</el-menu-item>
            <el-menu-item index="2-2-2" @click="$router.push({ name: 'projectAdmin', params: { title: 'AIChat项目' } })">项目配置</el-menu-item>
            <el-menu-item index="2-2-3" @click="$router.push({ name: 'commonProblem', params: { title: 'AIChat项目' } })">常见问题</el-menu-item>
            <el-menu-item index="2-2-4" @click="$router.push({ name: 'page_pay_way', params: { title: 'AIChat项目' } })">付款方式</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="2-3">
          <template slot="title">营收管理</template>
          <el-menu-item-group>
            <el-menu-item index="2-3-1" @click="$router.push({ name: 'daily_data', params: { title: 'AIChat项目' } })">收支情况</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-menu-item-group>
    </el-submenu>



      <el-submenu index="5" style="display: ">
        <template slot="title"><i class="el-icon-setting"></i>解密项目</template>
        <el-menu-item-group>



          <el-submenu index="2-1">
            <template slot="title">用户管理</template>
            <el-menu-item-group>
              <!-- <el-menu-item index="5-1-1" @click="$router.push({ name: 'user_list', params: { title: '压缩包解密项目' } })">用户列表</el-menu-item> -->

            </el-menu-item-group>
          </el-submenu>




          <el-submenu index="5-2">
            <template slot="title">配置管理</template>
            <el-menu-item-group>
              <el-menu-item index="2-2-1" @click="$router.push({ name: 'views_decryption-setting-pagePayWay', params: { title: '压缩包解密项目' } })">支付账号配置</el-menu-item>
              <!-- <el-menu-item index="2-2-2" @click="$router.push({ name: 'projectAdmin', params: { title: 'AIChat项目' } })">项目配置</el-menu-item>
              <el-menu-item index="2-2-3" @click="$router.push({ name: 'commonProblem', params: { title: 'AIChat项目' } })">常见问题管理</el-menu-item>
              <el-menu-item index="2-2-4" @click="$router.push({ name: 'page_pay_way', params: { title: 'AIChat项目' } })">付款方式配置</el-menu-item>
              <el-menu-item index="2-2-5" @click="$router.push({ name: 'chat_rule', params: { title: 'AIChat项目' } })">智能客服回复规则</el-menu-item> -->
            </el-menu-item-group>
          </el-submenu>




<!-- 
          <el-submenu index="2-3">
            <template slot="title">营收管理</template>
            <el-menu-item-group>
              <el-menu-item index="2-3-1" @click="$router.push({ name: 'daily_data', params: { title: 'AIChat项目' } })">收支情况</el-menu-item>
            </el-menu-item-group>
          </el-submenu> -->



        </el-menu-item-group>
      </el-submenu>
    <div>
      <div style="text-align: center" class="ban"></div>
    </div>
    <!-- <div class="fl_cen">
      <div class="fold_btn" @click="toggleSidbar">
        <i class="el-icon-s-fold icon_fold pointer"></i>
      </div>
    </div> -->
  </el-menu>
  </div>
</template>

<script>
export default {
  props: {
    isCollapse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    toggleSidbar() {
      this.$emit("toggleSidbar");
    },
  },
};
</script>
<style>
.el-submenu .el-menu-item {
  min-width: unset;
}

.el-menu {
  position: relative;
  border-right: 0;
}

.el-menu::-webkit-scrollbar {
  display: none;
}

.img {
  margin-right: 8px;
  padding-left: 5px;
  width: 16px;
}

.el-icon-fold {
  font-size: 18px;
}

.el-submenu__title {
  padding: 13px 20px 13px 24px;
  color: rgba(48, 49, 51, 1);
}

.el-submenu__icon-arrow {
  margin-top: -3px;
}

itle i {
  color: rgba(48, 49, 51, 1);
}

.el-menu-item.is-active {
  color: rgba(64, 158, 255, 1);
}

.fold_btn {
  right: 6px;
  width: 36px;
  height: 32px;
  border-radius: 4px;
  background: rgba(245, 247, 250, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.wd40 .fl_cen {
  margin-top: 10px;
  margin-right: 14px;
}

.wd40 .ban {
  display: none;
}
</style>
