import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "@/assets/css/style.css";
import ElPage from "@/components/ElPage";
import echarts from "@/utils/echarts";
import { routeOpen } from "@/utils/tool";


import { encrypt, decrypt } from '@/utils/encryp.js';
 

Vue.component("ElPage", ElPage);

Vue.use(ElementUI);
Vue.prototype.$routeOpen = routeOpen;
Vue.prototype.$encrypt = encrypt;
Vue.prototype.$decrypt = decrypt;
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;

Vue.directive("preventReclick", {
  inserted(el, binding) {
    el.addEventListener("click", () => {
      if (!el.disabled) {
        el.disabled = true;
        setTimeout(() => {
          el.disabled = false;
        }, binding.value || 1000);
      }
    });
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
