<template>
  <div class="navbar">
    <div class="side_title">
      <span>
        <el-select v-model="value" @change="change" class="project_select">
          <el-option label="信息通查管理端" value="https://admin.wnc.ocnet.cc/">
          </el-option>
          <el-option
            label="多项目联合管理端"
            value="http://admin.aichat.ocnet.cc/"
          >
          </el-option>
        </el-select>
      </span>
    </div>
    <div class="fl_end">
      <span class="refresh_word pointer" @click="$router.go(0)"> 刷新 </span>
      <span class="line pointer"> | </span>
      <span class="user_img pointer" @click="showToken">
        <img src="@/assets/user/用户-24备份.png" alt="" />
      </span>
      <span class="end_word"
        ><span class="pointer" @click="showToken" style="margin-right: 25px">{{
          account
        }}</span
        ><span class="pointer" @click="lgout">退出</span></span
      >
    </div>
    <el-dialog title="用户token" :visible.sync="showDialog">
      <div>{{ $store.state.token }}</div>
    </el-dialog>
  </div>
</template>

<script>
import screenfull from "screenfull";
export default {
  create() {},
  props: {},
  data() {
    return {
      value: "http://admin.aichat.ocnet.cc/",
      rootRoute: "",
      showFold: false,
      fullscreen: false,
      showDialog: false,
      account: localStorage.getItem("account") || "",
    };
  },
  methods: {
    fullScreen() {
      if (!screenfull.isEnabled) {
        this.$message({
          message: "you browser can not work",
          type: "warning",
        });
        return false;
      }
      screenfull.toggle();
    },
    reload() {
      window.location.reload();
    },
    change() {
      window.location.href = this.value;
    },
    showToken() {
      this.showDialog = true;
    },
    lgout() {
      this.$confirm("确定要退出登录吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store.dispatch("lgout");
          this.$router.push("/login");
          this.$message.success("退出登录成功");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>

<style scoped>
::v-deep.project_select .el-input__inner {
  background: rgba(12, 25, 61, 1);
  border-color: rgba(12, 25, 61, 1);
  color: #fff;
}
::v-deep .project_select .el-icon-arrow-up:before {
  content: "";
}
.fl_end {
  display: flex;
  justify-content: flex-end;
}
.side_title {
  text-align: center;
  color: #fff;
  line-height: 48px;
  height: 48px;
  opacity: 1;
  background: rgba(12, 25, 61, 1);
  margin-left: 33px;
}
.navbar {
  height: 48px;
  display: flex;
  background: rgba(12, 25, 61, 1);
  color: #fff;
  line-height: 48px;
  font-size: 14px;
  justify-content: space-between;
}
.refresh_word {
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(245, 247, 250, 1);
  text-align: left;
  margin-right: 25px;
}
.line {
  width: 1px;
  height: 20px;
  opacity: 1;
  border-radius: 0.5px;
  margin-right: 24px;
}
.user_img {
  margin-top: 6px;
  width: 24px;
  height: 24px;
  opacity: 1;
  padding-right: 4px;
}
.end_word {
  opacity: 1;
  margin-right: 36px;
}
</style>
