<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <router-view :key="key" />
    </transition>
  </section>
</template>

<script>
export default {
  name: "AppMain",
  computed: {
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style scoped>
.app-main {
  /*50 = navbar  */
  min-height: calc(100vh - 200px);
  position: relative;
  background-color: #fff;
  margin: 10px;
  padding: 10px;
  min-width: 1200px;
}
.fixed-header + .app-main {
  padding-top: 50px;
}
</style>
