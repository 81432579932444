<template>
  <el-container>
    <nav-bar class="nav_bar" />
    <el-aside ref="aside" style="width: 180px" :class="{ wd40: showSidbar }">
      <sid-bar
        ref="sidbar"
        :isCollapse="showSidbar"
        @toggleSidbar="toggleSidbar"
      ></sid-bar>
    </el-aside>

    <el-main class="main">
      <div class="page_title">
        <span class="level1_title">{{ firstTitle }}</span
        ><span class="title_line">/</span
        ><span class="level2_title">{{ secondTitle }}</span>
      </div>
      <app-main class="app_main" />
    </el-main>
    <el-backtop target="" :bottom="100"></el-backtop>
  </el-container>
</template>

<script>
import NavBar from "./navbar/NavBar";
import SidBar from "./sidbar/SidBar";
import AppMain from "./components/AppMain";
export default {
  created() {
    this.firstTitle = this.$store.state.routeTitle;
    this.secondTitle = this.$route.meta.title;
  },

  components: {
    NavBar,
    SidBar,
    AppMain,
  },
  data() {
    return {
      show: false,
      firstTitle: "",
      secondTitle: "",
      showSidbar: false,
    };
  },
  methods: {
    toggleSidbar() {
      this.showSidbar = !this.showSidbar;
    },
  },
  watch: {
    $route: {
      handler: function (val) {
        this.firstTitle =
          this.$route.query.routeTitle || this.$store.state.routeTitle;
        this.secondTitle = val.meta.title || this.$route.meta.title;
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>

<style scoped>
.humBtn {
  font-size: 20px;
}
.nav_bar {
  width: 100%;
  position: absolute;
}
.el-aside {
  background-color: #fff;
  margin-left: -5px;
}
.wd40 {
  width: 45px !important;
  margin-left: -2px !important;
}
.main {
  margin-top: 50px;
}
.icon_fold {
  width: 15px;
  height: 12.5px;
}
.page_title {
  height: 48px;
  background: rgba(255, 255, 255, 1);
  line-height: 48px;
  padding-left: 16px;
  margin-left: 2px;
}
.level1_title {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.01px;
  line-height: 22px;
  color: rgba(144, 147, 153, 1);
  text-align: left;
}
.level2_title {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.01px;
  line-height: 22px;
  color: rgba(48, 49, 51, 1);
  text-align: left;
}
.title_line {
  margin: 0 10px;
  color: rgba(144, 147, 153, 1);
}
</style>
